import arrayShuffle from "array-shuffle";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class IntroMV {
    constructor() {
        this.init();
    }

    init() {
        this.onLoad();
        this.mvrandomize();
        this.onScroll();
    }

    onLoad() {
        let flags = false;
        let loadCount = 0;

        //apngのロードを待つ。
        const mvlogo = new Image();
        const logopath = "./assets/img/top/loader/ph_logo_anim.png";
        mvlogo.src = logopath;
        mvlogo.onload = () => {
            flags = true;
        };

        const loadfunc = () => {
            const apng = [...document.querySelectorAll(".js-apng")];
            apng.forEach((el) => el.setAttribute("src", logopath));
            this.intro();
        };

        const watchFlags = () => {
            let loaded = flags;

            if (loaded || loadCount >= 100) {
                loadfunc();
            } else {
                setTimeout(watchFlags, 100);
                loadCount++;
            }
        };

        watchFlags();
    }

    intro() {
        setTimeout(() => {
            document.body.classList.add("-introplay");
        }, 2200);
        setTimeout(() => {
            document.body.classList.add("-mvready");
        }, 2200);
        setTimeout(() => {
            document.body.classList.add("-ready");
        }, 3500);
    }

    mvrandomize() {
        const objs = [...document.querySelectorAll(".mainvisual__obj__item")];
        let randarray = [...Array(objs.length)].map((el, index) => index); //length分の[0,1,2,...]のような配列を作成

        randarray = arrayShuffle(randarray);

        //初期化
        objs.forEach((el) => el.classList.remove("-secondary"));

        //間引くオブジェクトをランダムに決定
        randarray.forEach((el, index) => {
            if (index <= 2) {
                objs[el].classList.add("-secondary");
            }
        });
    }

    onScroll() {
        const mvscroll = document.querySelector(".mvscroll");

        ScrollTrigger.create({
            trigger: mvscroll,
            start: "top top",
            onEnter: () => {
                document.body.classList.add("-mvscroll");
            },
            onLeaveBack: () => {
                this.mvrandomize(); //再配置
                document.body.classList.remove("-mvscroll");
            },
        });
    }
}
