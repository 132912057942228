import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";

import Swiper from "swiper/bundle";

export default class Discography {
    constructor() {
        this.loadLength = 3;
        this.init();
    }

    init() {
        this.getData(); //initial Get Data
        this.setCallback();
    }

    setCallback() {
        const on_get_data = (data) => {
            this.setDom(data);

            //SPのみのスライダ-はAjax DOM生成後にinitする
            const discoSwiper = new Swiper(".discoSwiper", {
                loop: true,
                slidesPerView: "auto",
                centeredSlides: true,
                spaceBetween: 20,
            });
        };

        //callbackはグローバル変数に
        window.on_get_data = on_get_data;
    }

    getData() {
        axios.get(`https://www.sonymusic.co.jp/json/v2/artist/Flow/discography/start/0/count/${this.loadLength}/callback/on_get_data`, {
            adapter: axiosJsonpAdapter,
        });
    }

    setDom(data) {
        if (!data) {
            return;
        }

        let dom = "";
        let dom_nrw = "";
        const wrapper = document.querySelector(".js-discography_wrapper");
        const wrapper_nrw = document.querySelector(".js-discography_wrapper_nrw");

        const categoryMap = {
            シングル: "single",
            アルバム: "album",
            DVD: "bd/dvd",
            BD: "bd/dvd",
            配信限定: "streaming",
        };

        data.items.forEach((item) => {
            const category = categoryMap[item.type] || "";

            const content = `
            <a href="https://www.sonymusic.co.jp/artist/Flow/discography/${item.representative_goods_number}" target="_blank" class="c-imgzoombtn -noabs">
                <div class="thumb"><img src="${item.image_original}" alt=""/>
                </div>
                <h2 class="ttl">${item.title}</h2>
                <h3 class="info">${item.release_date}&nbsp;&nbsp;${item.price && "¥" + Math.floor(item.price).toLocaleString("ja-JP") + "（税込）"}</h3>
                <h4 class="category">#${category.toUpperCase()}</h4>
            </a>
            `;

            dom += `<li class="discography__list__item">${content}</li>`;
            dom_nrw += `<div class="swiper-slide"><div class="discography__list__item">${content}</div></div>`;
        });

        //wrapper毎に追加する
        wrapper.insertAdjacentHTML("beforeend", dom);
        wrapper_nrw.insertAdjacentHTML("beforeend", dom_nrw);
    }
}
