//libs swiperを使う際はbabel管理外にしないといけないので注意
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

//component
import Movie from "../common/ui/movie";
import Modal from "../common/ui/modal";
import Discography from "./discography";
import IntroMV from "./intromv";

export default class Main {
    constructor() {
        this.movie = new Movie();
        this.modal = new Modal();
        this.discography = new Discography();
        this.intromv = new IntroMV();
        this.init();
    }

    init() {
        this.swiper();
    }

    swiper() {
        const bnrSwiper = new Swiper(".bnrSwiper", {
            loop: true,
            slidesPerView: "auto",

            breakpoints: {
                835: {
                    spaceBetween: 10,
                },
            },
            navigation: {
                nextEl: ".bnrSwiper-next",
                prevEl: ".bnrSwiper-prev",
            },
            pagination: {
                el: ".bnrSwiper-pagination",
                clickable: true,
            },
        });

        const movieSwiper = new Swiper(".movieSwiper", {
            loop: true,
            slidesPerView: "auto",
            centeredSlides: true,
            spaceBetween: 20,

            breakpoints: {
                835: {
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: ".movieSwiper-next",
                prevEl: ".movieSwiper-prev",
            },

            on: {
                afterInit: () => {
                    //動的に追加された要素にイベントを再設定する
                    this.modal.resetEvent();
                    this.modal.eventBind();
                    this.movie.eventBind();
                },
            },
        });

        //goodsの個数が少ないときはスライダーをアクティブにしないように。
        const goodsLoopAvailable = [...document.querySelectorAll(".goods__list__item")].length > 3;

        const goodsSwiper = new Swiper(".goodsSwiper", {
            loop: goodsLoopAvailable,
            slidesPerView: "auto",
            centeredSlides: true, //SPでは常にtrue
            spaceBetween: 20,

            breakpoints: {
                835: {
                    centeredSlides: goodsLoopAvailable,
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: ".goodsSwiper-next",
                prevEl: ".goodsSwiper-prev",
            },
            pagination: {
                el: ".goodsSwiper-pagination",
                clickable: true,
            },

            watchOverflow: true,
        });
    }
}
