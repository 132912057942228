export default class Movie {
    constructor() {
        this.player = null;
        this.init();
    }

    init() {
        const $moviemodal = document.querySelector(".js-moviemodal");

        if ($moviemodal !== null) {
            this.setup();
        }
    }

    setup() {
        const tag = document.createElement("script");

        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player("player", {
                height: "360",
                width: "640",
            });
        };
    }

    eventBind() {
        const closebuttons = document.querySelectorAll(".js-modal_close");
        const openbuttons = document.querySelectorAll(".js-modal_open");

        [...closebuttons].forEach((el) => {
            el.addEventListener("click", () => {
                this.player.stopVideo();
            });
        });

        [...openbuttons].forEach((el) => {
            el.addEventListener("click", () => {
                const videoID = el.getAttribute("data-videoID");
                this.loadVideo(videoID);
            });
        });
    }

    loadVideo(id) {
        //デフォルトでautoplayされる。
        this.player.loadVideoById({ videoId: id });
    }
}
